import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'
import { useVideoCalls } from '../../../contexts/VideoCallsProvider'
// import { ListItemLoader } from '../../shared/ListItemLoader'
import '@webex/widgets/dist/css/webex-widgets.css'

// const WebexMeeting = require('@webex/widgets')
// const { WebexMeetingsWidget } = WebexMeeting

const VideoCallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const CallHeader = styled.div`
  display: flex;
  padding: ${spacing.lg};
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  padding: ${spacing.lg};
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  width: 300px;
  height: 48px;
  border: none;
  font-size: inherit;
  border-radius: ${borderRadius};
`

const AcceptButton = styled(Button)`
  margin-left: ${spacing.xl};
  color: white;
  background-color: ${colors.jodaDark};
`

const DeclineButton = styled(Button)`
  color: white;
  background-color: ${colors.error};
`

export const VideoCallPage = () => {
  const { authUser } = useContext(AuthContext)
  const {
    videoCall,
    onVideoCallDeclined,
    onVideoCallAccepted,
  } = useVideoCalls()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [audio] = useState(new Audio('video-call-ringtone.mp3'))
  // const [join, setJoin] = useState(false)
  const isVideoCallRecipient = videoCall.recipient_id === authUser?.id
  // const token =
  //   videoCall.recipient_id === authUser?.id
  //     ? videoCall.recipient_token
  //     : videoCall.sender_token
  // const displayName = isVideoCallRecipient
  //   ? videoCall.recipient_name
  //   : videoCall.sender_name

  // @ts-ignore
  // const isNative = window?.ReactNativeWebView
  // const roomUrl = `${videoCall.sip_address}`
  const caller = isVideoCallRecipient
    ? videoCall.sender_name
    : videoCall.recipient_name

  const acceptCall = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/video-calls/${videoCall.id}/accept`,
      {
        method: 'PATCH',
      }
    )
      .then((call) => {
        setStatus('idle')
        onVideoCallAccepted(call)
        window.location.href = `jodapro://mylogin?token=${call.recipient_token}&toCall=${call.sip_address}&name=${authUser?.first_name}  ${authUser?.last_name}&displayName=${authUser?.first_name}  ${authUser?.last_name}`
      })
      .catch(() => setStatus('failure'))
    audio.pause()
  }

  const declineCall = () => {
    authenticatedFetch(`${environment.API_URL}/video-calls/${videoCall.id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        setStatus('idle')
        onVideoCallDeclined()
      })
      .catch(() => {
        onVideoCallDeclined()
      })
    audio.pause()
  }
  useEffect(() => {
    if (videoCall && isVideoCallRecipient && !videoCall.accepted) {
      audio.play()
    }
  }, [videoCall, audio, isVideoCallRecipient])

  // const callHasStarted = videoCall.accepted || !isVideoCallRecipient

  // const delay = (ms: any) => new Promise((res) => setTimeout(res, ms))
  // const handleLoadWidget = async () => {
  //   const joinButton: any = document.getElementsByClassName(
  //     'wxc-button wxc-meeting-control__control-button wxc-button--join wxc-button--pressed'
  //   )
  //   //End call button: wxc-button wxc-meeting-control__control-button wxc-button--cancel
  //   //SelfView wxc-local-media wxc-in-meeting__local-media-in-meeting
  //   if (joinButton && joinButton.length > 0) {
  //     joinButton[0].style.display = 'none'
  //     await delay(2000)
  //     try {
  //       joinButton[0].click()
  //     } catch (error) {}
  //     await delay(2000)
  //     const cancelButton: any = document.getElementsByClassName(
  //       'wxc-button wxc-meeting-control__control-button wxc-button--cancel'
  //     )
  //     const selfView: any = document.getElementsByClassName(
  //       'wxc-local-media wxc-in-meeting__local-media-in-meeting'
  //     )
  //     if (cancelButton && cancelButton.length > 0) {
  //       cancelButton[0].style.display = 'none'
  //     }
  //     await delay(4000)
  //     if (selfView && selfView.length > 0) {
  //       selfView[0].style.display = 'none'
  //     }
  //     await delay(2000)
  //     const controlButtons: any = document.getElementsByClassName(
  //       'wxc-meeting-control-bar__controls'
  //     )
  //     if (controlButtons && controlButtons.length > 0) {
  //       controlButtons[0].style.display = 'none'
  //     }
  //     setJoin(true)
  //   }
  // }

  // useEffect(() => {
  //   if (!join) {
  //     const id = setInterval(handleLoadWidget, 4000)
  //     return () => clearInterval(id)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [join])
  return (
    <VideoCallWrapper>
      <CallHeader>
        <h1>
          {videoCall.accepted
            ? `Videosamtale med ${caller}`
            : isVideoCallRecipient
            ? `${caller} ringer!`
            : `Ringer ${caller}`}
        </h1>
      </CallHeader>
      {/* {!isNative && (
        <>
          {callHasStarted ? (
            <WebexMeetingsWidget
              accessToken={
                videoCall.sender_id === authUser?.id
                  ? videoCall.sender_token
                  : videoCall.recipient_token
              }
              meetingDestination={roomUrl}
              style={{
                width: '1000px',
                height: '500px',
                margin: 'auto',
                // width: '50%'
                // border: '3px solid green',
                padding: '10px',
              }}
            />
          ) : (
            <ListItemLoader />
          )}
        </>
      )} */}

      {!videoCall.accepted && (
        <ButtonGroup>
          <DeclineButton disabled={status === 'pending'} onClick={declineCall}>
            {isVideoCallRecipient ? 'Avslå' : 'Legg på'}
          </DeclineButton>
          {isVideoCallRecipient && (
            <AcceptButton disabled={status === 'pending'} onClick={acceptCall}>
              Svar
            </AcceptButton>
          )}
        </ButtonGroup>
      )}

      {videoCall.accepted && (
        <ButtonGroup>
          <DeclineButton disabled={status === 'pending'} onClick={declineCall}>
            Legg på
          </DeclineButton>
        </ButtonGroup>
      )}
    </VideoCallWrapper>
  )
}
