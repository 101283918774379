import React, { useContext } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, navBarHeightSmall } from '../styles/styles'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

const TabBarStyled = styled.ul`
  position: fixed;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${navBarHeightSmall};
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.black10};
  z-index: 1;
`

const TabItem = styled.li`
  width: calc(100% / ${(props: { numItems: number }) => props.numItems});
  display: flex;
  align-items: center;
  justify-content: center;
`

const TabInner = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  padding: 0 0.5rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  color: inherit;
  border-radius: ${borderRadius};
  transition: all 0.25s ease-out;

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius};
    content: '';
    transform: scale(0);
    transition: all 0.25s ease-out;
    z-index: -1;
  }

  &.active {
    color: white;

    &::after {
      transform: scale(1);
      background: ${colors.joda};
    }
  }
`

export const TabBar = () => {
  const { settings } = useContext(AuthContext)

  // @ts-ignore
  const isNative = window?.ReactNativeWebView

  const numItems = settings.jodabook_contacts && !isNative ? 4 : 3

  return (
    <TabBarStyled>
      <TabItem numItems={numItems}>
        <TabInner to="/book" activeClassName="active">
          Boka
        </TabInner>
      </TabItem>

      <TabItem numItems={numItems}>
        <TabInner to="/profile" activeClassName="active">
          Meg
        </TabInner>
      </TabItem>

      <TabItem numItems={numItems}>
        <TabInner to="/album" activeClassName="active">
          Album
        </TabInner>
      </TabItem>

      {settings.jodabook_contacts && (
        <TabItem numItems={numItems}>
          <TabInner to="/contacts" activeClassName="active">
            Kontakter
          </TabInner>
        </TabItem>
      )}
    </TabBarStyled>
  )
}
